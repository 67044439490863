import { FeaturePreviewCard } from '@/components/common/cards/FeaturePreviewCard';
import { t } from '@/services/translator';
import { ReactNode, MouseEvent, useCallback } from 'react';
import { Currency } from '@/components/common/Currency';
import { OrderSnapshot } from '@/types/order';
import { useLazySearchWriterOrdersQuery } from '@/api/writerOrders';
import { WriterOrderStatuses } from '@/types/writerOrder';
import { FEATURE, getFeatureURL } from '@/config/features';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { breadcrumbsSelector, setBreadcrumbs } from '@/store/breadcrumbs/breadcrumbsSlice';
import { OrderDeadline } from '@feature/order/OrderDeadline';
import { DateTime } from '@/components/common/DateTime';

export type OrderCardProps = {
  idOrder: number;
  idWriterOrder?: number;
  snapshot: OrderSnapshot;
  status?: ReactNode;
  headingBottom?: ReactNode;
  alreadyClaimed?: boolean;
  feature: 'ClaimedWriterOrder' | 'ActiveWriterOrder' | 'InactiveWriterOrder';
};

export const OrderCard: FC<OrderCardProps> = ({ idOrder, idWriterOrder, feature, snapshot, status, headingBottom, alreadyClaimed }) => {
  const [searchWriterOrder] = useLazySearchWriterOrdersQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const breadcrumbs = useAppSelector(breadcrumbsSelector);

  const {
    title,
    workType,
    academicDiscipline,
    academicLevel,
    dynamicUnits,
    dynamicWords,
    sources,
    citationStyle,
    dateDue,
    dynamicWriterTotalPrice,
    customAcademicDiscipline,
    customCitationStyle,
    urgency,
    dateDraftDue,
  } = snapshot;

  const getCitationSources = () => {
    const processedCitationStyle = citationStyle?.writerName || customCitationStyle;

    if (processedCitationStyle && sources) {
      return processedCitationStyle + ', ' + sources;
    } else if (!processedCitationStyle && sources) {
      return sources;
    } else if (processedCitationStyle && !sources) {
      return processedCitationStyle;
    } else {
      return t('misc.notAvailable');
    }
  };

  const isNewOrder = ['ClaimedWriterOrder'].includes(feature);

  const redirectToClaimedOrder = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      try {
        const queryOrdersResult = await searchWriterOrder({
          idOrder: [idOrder],
          idStatus: [WriterOrderStatuses.Claimed],
          size: 1,
        }).unwrap();

        const targetOrder = queryOrdersResult.items[0];
        dispatch(
          setBreadcrumbs({
            items: [
              ...breadcrumbs,
              {
                from: location.pathname + window.location.search,
                title: t('order.order') + (' ID ' + targetOrder.id),
                to: FEATURE.ClaimedWriterOrder.path + '/' + targetOrder.id,
              },
            ],
          }),
        );

        navigate(getFeatureURL('ClaimedWriterOrder', targetOrder.id));
      } catch (err) {}
    },
    [breadcrumbs, dispatch, idOrder, location.pathname, navigate, searchWriterOrder],
  );

  const onClick = alreadyClaimed ? redirectToClaimedOrder : undefined;

  return (
    <FeaturePreviewCard
      feature={feature}
      id={idWriterOrder}
      title={`${t('order.orderId')}: ${idOrder || idWriterOrder}`}
      status={status}
      headingBottom={headingBottom}
      onClick={onClick}
      details={[
        {
          description: title,
        },
        {
          title: t('order.workType'),
          condition: !!workType && isNewOrder,
          description: workType?.writerName,
        },
        {
          title: t('order.academicLevel'),
          condition: !!academicLevel && isNewOrder,
          description: academicLevel?.writerName,
        },
        {
          title: t('order.academicDiscipline'),
          condition: !!academicDiscipline || !!customAcademicDiscipline,
          description: academicDiscipline?.writerName || customAcademicDiscipline,
        },
        {
          title: t('order.unitsWords'),
          condition: !!dynamicUnits || !!dynamicWords,
          description: `${dynamicUnits} ${t('order.page', { count: dynamicUnits })} / ${dynamicWords} ${t('order.word', {
            count: dynamicWords,
          })}`,
        },
        {
          title: t('order.citationSources'),
          condition: isNewOrder,
          description: getCitationSources(),
        },
        {
          title: t('order.finalPrice'),
          condition: !!dynamicWriterTotalPrice,
          description: <Currency value={dynamicWriterTotalPrice} />,
        },
        {
          title: <span style={{ display: 'inline-block', width: 60 }}>{t('misc.dateDraftDue')}:</span>,
          condition: !!dateDraftDue,
          description: dateDraftDue ? <DateTime value={dateDraftDue} /> : t('misc.notAvailable'),
        },
        {
          title: <span style={{ display: 'inline-block', width: 60 }}>{t('order.deadline')}</span>,

          description: dateDue ? (
            <div>
              <OrderDeadline variant="card" dateDue={dateDue} deadline={Number(urgency)} />
            </div>
          ) : (
            t('misc.notAvailable')
          ),
        },
      ]}
    />
  );
};
